import { FormEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import userApi from "../apis/user";
import DocumentTitle from "../utils/DocumentTitle";

const Page = () => {

  const navigate = useNavigate();
  const { type, value } = useParams<{ type: string, value: string }>();
  const [ data, setData ] = useState<Dispute | null>(null);
  const [ loaded, setLoaded ] = useState<boolean>(false);

  // Form
  const [ formData, setFormData ] = useState<{ [key: string]: string }>({
    type: String(type || 'user_name'),
    value: String(value || ''),
  }); 

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formData.type && !formData.value) 
      return toast.error("Please enter a search term");

    navigate(`/disputes/${formData.type}/${formData.value}`);
  }

  // Fetch
  const getDispute = useCallback(async () => {
    userApi.getDispute(type || 'user_name', value || '')
      .then((r) => {
        if (r?.user) {
          DocumentTitle(`${String(r?.user)}`);
          setData(r);
          setLoaded(true)
        } else 
          setData(null);

      })
      .catch((e) => toast.error(e));
  }, [ type, value ]);

  // Initial Render
  useEffect(() => {
    setFormData({ ...formData, type: String(type || 'user_name'), value: String(value || '') });
    getDispute();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ getDispute ]);

  useEffect(() => {
    DocumentTitle('Disputes');
  }, []);

  // Options
  const options = [
    { label: 'Username', value: 'user_name' },
    { label: 'Subscription ID', value: '_id' },
    { label: "Customer ID", value: "customer_id" },
    { label: "Email Address", value: "email" },
  ];

  // Fields
  const fields = [
    { label: "User Found", value: data?.['noUser'] ? 'No' : 'Yes'},
    { label: "Subscription Found", value: data?.['subFound'] ? 'Yes' : 'N/A' },
    { label: 'Username', value: data?.Username },
    { label: "Signup date", value: data?.['Signup date'] },
    { label: "Last active date", value: data?.['Last active date'] || 'N/A' },
    { label: "Students imported", value: data?.['Students imported'] || 0 },
    { label: "Assignments created", value: data?.['Assignments created'] || 0 },
    { label: "Assessments created", value: data?.['Assessments created'] || 0 },
    { label: "Skills practiced ad-free", value: data?.['Skills practiced ad-free'] || 0 },
    { label: "Student accounts", list: (data?.['Student accounts'] || []).map((item) => (
      <div className="bg-white rounded-lg mb-2 text-xs font-normal p-2">
        <div>{item.user_name}</div>
        <div>{item.email}</div>
        <div>{String(item?.signup_date || '')}</div>
      </div>
    ))},
  ];

  return (
    <div className="tw-container tw-padding">
      <div className="flex flex-wrap justify-between">
        <h1 className="w-full lg:w-auto">Disputes</h1>
        <form onSubmit={handleSubmit} className="my-auto w-full lg:w-auto">
          <div className="flex flex-wrap">
            <select className="w-full lg:w-40 mb-1 lg:mb-0 rounded-r-none" value={formData.type} onChange={(e) => setFormData({ ...formData, type: e.target.value })}>
              {options.map((o) => (
                <option key={o.value} value={o.value}>{o.label}</option>
              ))}
            </select>

            <input 
              type="search" 
              name="search" 
              className="w-full lg:w-52 mb-1 lg:mb-0 rounded-l-none"
              placeholder="Enter search term"
              defaultValue={formData.value} 
              onChange={(e) => setFormData({ ...formData, value: e.target.value })} 
            />

            <button type="submit" className="button blue ml-2">Search</button>
          </div>
        </form>
      </div>

      <div className="w-full sm:w-11/12 md:w-10/12 lg:w-8/12 xl:w-1/2 mx-auto bg-gray-100 rounded-lg p-6 mt-12 text-sm lg:text-base">
        {(loaded && data?.user) && (
          <>
            <div className="font-semibold pb-2 mb-2 border-b-2">{data?.user}</div>
            {fields.map((field) => (
              <div key={field.value} className="grid grid-cols-1 lg:grid-cols-2 pb-2 lg:pb-0">
                <div>{field.label}</div>
                {field.list ? (
                  <div className="grid grid-cols-1">
                    {field.list.map((item, i) => <div key={i} className="font-semibold">{item}</div>)}
                  </div>
                ) : <div className="font-semibold">{field.value}</div>}
              </div>
            ))}
          </>
        )}

        {(loaded && !data) && (
          <div className="text-center py-24">
            <div className="font-semibold">No results found</div>
          </div>
        )}

        {(!loaded && !value) && (
          <div className="text-center py-24">
            <div className="font-semibold">Please fill out the fields above.</div>
          </div>
        )}
        
      </div>
    </div>
  )
}

export default Page;