import axios from "../utils/axiosConfig";
import Config from "../Config";

const getList = async (): Promise<CurriculumByCountry[]> => {
  const response = await axios.get(`${Config.apiUrl}/admin/curriculum/list`)
  const data = response.data;
  return data;
}

const getListForSelect = async () => {
  const data = await getList();
  const selectOptions: { value: string, label: string }[] = [];

  data.forEach((cc) => {
    cc.curriculums.map((item) => {
      return selectOptions.push({
        value: item.curriculum_key,
        label: `[${item.curriculum_key}] ${item.name}`
      });
    })
  });

  return [ ...selectOptions ];
};

export default { getList, getListForSelect };