import { FormEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import eventApi from "../apis/event";
import EventModal from "../components/Event/EventModal";
import Pagination from "../components/Pagination";
import DocumentTitle from "../utils/DocumentTitle";
import isJSON from "../utils/isJSON";

const Page = () => {

  const itemsPerPage = 25;
  const [ search, setSearch ] = useState<string>('');
  const [ eventData, setEventData ] = useState<Event[]>([]);
  const [ nextPage, setNextPage ] = useState<boolean>(true);
  const [ current, setCurrent ] = useState<number | null>(null);
  const [ loaded, setLoaded ] = useState<boolean>(false);
  const [ skip, setSkip ] = useState<number>(0);

  // Form
  const [ formData, setFormData ] = useState<{ [key: string]: string }>({});
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSkip(0);
    setSearch(formData.search);
  }

  // Fetch
  useEffect(() => {
    eventApi.getList(skip, itemsPerPage, search)
      .then((r) => {
        setEventData([ ...r.data ]);
        setNextPage(skip < r.total && r.data.length >= itemsPerPage);
        setLoaded(true);
      })
      .catch((e) => toast.error(e));
  }, [ skip, itemsPerPage, search ]);

  useEffect(() => {
    DocumentTitle('Events');
  }, []);

  return (
    <>
      {/* Modal */}
      {current !== null && <EventModal data={eventData[current]} isOpened={current !== null} onClose={() => setCurrent(null)} />}

      {/* Page */}
      <div className="tw-container tw-padding">
        <div className="flex flex-wrap justify-between">
          <h1>Events</h1>
          <form onSubmit={handleSubmit} className="my-auto w-full lg:w-96">
            <input 
              type="search" 
              name="search" 
              className="w-full"
              placeholder="Search by Action, Reference or Value"
              defaultValue={formData.search} 
              onChange={(e) => setFormData({ ...formData, search: e.target.value })} 
            />
          </form>
        </div>

        <div className="table mt-4">
          <div className="head">
            <div className="w-64">User</div>
            <div className="hm-m w-40">Action</div>
            <div className="hm w-48">Reference</div>
            <div className="hm w-64">Value / Misc</div>
            <div className="hm-m w-40 ml-auto">Date</div>
          </div>

          {/* Data */}
          {eventData.map((event, index) => (
            <div key={event._id} className="row">
              <div className="w-64">
                {event.user?.first_name || 'Unknown'} {event.user?.last_name} ({event.user?.user_name})
                <div style={{ marginTop: '-6px' }} className="md:hidden text-xs opacity-60">
                  {event.action}:{event.reference}
                </div>
              </div>
              <div className="hm-m w-40">{event.action}</div>
              <div className="hm w-48">{event.reference}</div>
              <div className="hm w-64">
                {isJSON(event.value) 
                  ? <button type="button" className="text-blue-400" onClick={() => setCurrent(index)}>Show Query</button>
                  : event.value
                }
              </div>
              <div className="hm-m w-40 ml-auto">{event.created_date.replace('T', ' ').split('.')[0]}</div>
            </div> 
          ))}

          {/* Pagination */}
          {loaded && 
            <Pagination 
              skip={skip}
              isNextPage={nextPage} 
              nextPage={() => setSkip(skip + itemsPerPage)} 
              prevPage={() => setSkip(skip - itemsPerPage)} 
            />
          }
        </div>
      </div>
    </>
  )
}

export default Page;