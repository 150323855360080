import Config from "../Config";

export default (v: string = '', reverse: boolean = false) => {
  if (!document) return;

  if (!v)
    document.title = `${Config.name}`;
  else if (v && !reverse) {
    document.title = `${v} - ${Config.name}`;
  } else if (v && reverse) {
    document.title = `${Config.name} - ${v}`;
  }
}