import { toast } from "react-toastify";
import userApi from "../../apis/user";
import Modal from "../Modal";
import { useNavigate } from "react-router-dom";

interface IProps {
  data: User | null;
  isOpened: boolean;
  onClose: () => void;
}

const DeleteModal = (props: IProps) => {

  const { data, isOpened, onClose } = props;
  const navigate = useNavigate();

  const handleSubmit = () => {
    userApi.remove(String(data?._id))
      .then((r) => {
        if (!r.errors) {
          toast.success("Deletion done!");
          navigate('/');
          onClose();
        } else 
          toast.error(r.errors);
      })
  }

  return (
    <>
      {/* Modal */}
      <Modal isOpened={isOpened} onClose={onClose}>
        <h2 className="h3 mb-3">Delete {data?.first_name || 'Unknown'} {data?.last_name} ({data?.user_name})</h2>
        <form method="put" className="grid grid-cols-1 gap-4" onSubmit={handleSubmit}>
          <p className="pt-4">
            Are you sure you want to delete {data?.first_name || 'Unknown'} {data?.last_name} ({data?.user_name})?
            <br/>
            If you delete this user, <strong>all of their data will be erased and will not be recoverable</strong>.
          </p>

          <div className="col-span-full flex space-x-2 justify-end">
            <button type="button" className="button red" onClick={handleSubmit}>Delete</button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default DeleteModal;