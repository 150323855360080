import axios from "axios";
import Config from "../Config";

const getList = async (skip: number, limit: number, search: string = '') => {
  const response = await axios.get(`${Config.apiUrl}/admin/user/list`, { params: { skip, limit, search } })
  const data = response.data;
  return data;
}

const getById = async (_id: string) => {
  const response = await axios.get(`${Config.apiUrl}/admin/user/${_id}`)
  const data = response.data;
  return data;
};

const getDispute = async (type: string, value: string) => {
  const response = await axios.get(`${Config.apiUrl}/admin/user/dispute`, { params: { [type]: value } })
  const data = response.data;
  return data;
};

const forgot = async (_id: string) => {
  const response = await axios.post(
    `${Config.apiUrl}/admin/user/forgot`, 
    new URLSearchParams(Object.entries({ _id })).toString(),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  )
  const data = response.data;
  return data;
}

const migrate = async (originId: string, recipientId: string, transferSubscription: boolean = false, transferIdentity: boolean = true) => {
  const formData = { originId, recipientId, transferSubscription: String(transferSubscription), transferIdentity: String(transferIdentity) };
  const response = await axios.post(
    `${Config.apiUrl}/admin/user/migrate`, 
    new URLSearchParams(Object.entries({ ...formData })).toString(),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  )
  const data = response.data;
  return data;
}

const remove = async (_id: string) => {
  const response = await axios.post(
    `${Config.apiUrl}/admin/user/delete`, 
    new URLSearchParams(Object.entries({ _id })).toString(),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  )
  const data = response.data;
  return data;
}

const update = async (originId: string, user: Partial<User>) => {
  const formData = { 
    _id: originId, 
    email: user.email, 
    first_name: user.first_name, 
    last_name: user.last_name, 
    role: user.role,
    subscription: user.subscription, 
    curriculum_key: user.curriculum_key
  };
  const response = await axios.post(
    `${Config.apiUrl}/admin/user/update`, 
    new URLSearchParams(Object.entries({ ...formData } as any)).toString(),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  )
  const data = response.data;
  return data;
}

export default { getList, getById, getDispute, update, remove, migrate, forgot };