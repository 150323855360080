import moment from "moment";
import { FormEvent, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import userApi from "../apis/user";
import Pagination from "../components/Pagination";
import Avatar from "../components/User/Avatar";
import DocumentTitle from "../utils/DocumentTitle";

const Page = () => {

  // Params
  const { query } = useParams<{ query: string }>();

  // State
  const itemsPerPage = 25;
  const [ search, setSearch ] = useState<string>(String(query || ''));
  const [ userData, setUserData ] = useState<User[]>([]);
  const [ nextPage, setNextPage ] = useState<boolean>(true);
  const [ loaded, setLoaded ] = useState<boolean>(false);
  const [ skip, setSkip ] = useState<number>(0);

  // Form
  const [ formData, setFormData ] = useState<{ [key: string]: string }>({
    search: String(query || '')
  }); 
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSkip(0);
    setSearch(formData.search);
  }

  // Fetch
  useEffect(() => {
    userApi.getList(skip, itemsPerPage, search)
      .then((r) => {
        setUserData([ ...r.data ]);
        setNextPage(skip < r.total && r.data.length >= itemsPerPage);
        setLoaded(true);
      })
      .catch((e) => toast.error(e));
  }, [ skip, itemsPerPage, search ]);

  useEffect(() => {
    setSearch(String(query || ''));
  }, [ query ]);

  useEffect(() => {
    DocumentTitle('Users');
  }, []);

  return (
    <div className="tw-container tw-padding">
      <div className="flex flex-wrap justify-between">
        <h1>Users</h1>
        <form onSubmit={handleSubmit} className="my-auto w-full lg:w-96">
          <input 
            type="search" 
            name="search" 
            className="w-full"
            placeholder="Search by Name, Email or Subscription"
            defaultValue={formData.search} 
            onChange={(e) => setFormData({ ...formData, search: e.target.value })} 
          />
        </form>
      </div>

      <div className="table mt-4">
        <div className="head">
          <div className="w-16" />
          <div className="w-52">Name</div>
          <div className="w-72 hm">Email Address</div>
          <div className="w-28 hm">Role</div>
          <div className="w-40 hm-l">Subscription</div>
          <div className="w-24 hm-l">Curriculum</div>
          <div className="w-32 hm-m ml-auto">Created</div>
        </div>

        {/* Data */}
        {userData.map((data) => (
          <Link key={data._id} className="row hover:bg-gray-500 hover:text-white transition ease-in-out duration-100" to={`/u/${data._id}`}>
            <div className="w-16">
              <Avatar data={data} className="w-10 h-10" />
            </div>
            <div className="w-52">
              <div className="truncate">{data.first_name || 'Unknown'} {data.last_name}</div>
              <div style={{ marginTop: '-10px' }} className="text-xs opacity-60">{data.user_name}</div>
            </div>
            <div className="w-72 hm">
              <div className="truncate">{data.email || 'unknown@mathgames.com'}</div>
              <div style={{ marginTop: '-10px' }} className="text-xs opacity-60">
                {data.email_verified ? 'Verified' : 'Not Verified'}
              </div>
            </div>
            <div className="w-28 hm">
              <div>{String(data?.role || '').substring(0, 1).toUpperCase()}{String(data?.role || '').substring(1, data.role?.length)}</div> 
              <div style={{ marginTop: '-10px' }} className="text-xs opacity-60">
                {data.title}
              </div>
            </div>
            <div className="w-40 hm-l">
              <div>{data.subscription?.planName || 'N/A'}</div> 
              <div style={{ marginTop: '-10px' }} className="text-xs opacity-60">
                {data.subscription?.status}
              </div>
            </div>
            <div className="w-24 hm-l">{data.curriculum_key}</div>
            <div className="w-32 hm-m ml-auto">
              {String(data.signup_date).split('T')[0]}
              {data?.last_active && (
                <div style={{ marginTop: '-8px' }} className="text-xs opacity-60">
                  Active {moment(data.last_active).fromNow()}
                </div>
              )}
            </div>
          </Link> 
        ))}

        {/* Pagination */}
        {loaded && 
          <Pagination 
            skip={skip}
            isNextPage={nextPage} 
            nextPage={() => setSkip(skip + itemsPerPage)} 
            prevPage={() => setSkip(skip - itemsPerPage)} 
          />
        }
      </div>
    </div>
  )
}

export default Page;