import Modal from "../Modal";
import ReactJson from 'react-json-view'

interface IProps {
  data: Event;
  isOpened: boolean;
  onClose: () => void;
}

const EventModal = (props: IProps) => {

  const { data, isOpened, onClose } = props;

  return (
    <>
      {/* Modal */}
      <Modal isOpened={isOpened} onClose={onClose}>
        <h2 className="h3 mb-3">View Event {data?._id || 'Unknown'}</h2>
        <ReactJson name={null} src={JSON.parse(data.value)} />
      </Modal>
    </>
  );
}

export default EventModal;