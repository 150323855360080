import { useState, createContext, useContext, useEffect, useRef, useCallback, ReactNode } from 'react';
import { CancelTokenSource } from 'axios';
import axios from "../utils/axiosConfig";
import Config from '../Config';

interface ContextType {
  userData: User | null;
  userLoaded: boolean;
  setUserData: (user: User | null) => void;
  getUserData: () => void;
  resetUserData: () => void;
}

export const UserContext = createContext<ContextType | null>(null);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const axiosCancelSource = useRef<CancelTokenSource | null>(null);

  const [userLoaded, setUserLoaded] = useState<boolean>(false);
  const [userData, setUserData] = useState<User | null>(null);

  const getUserData = useCallback(async () => {
    const response = await axios.get(`${Config.apiUrl}/user/getUserForProfile`, {
      cancelToken: axiosCancelSource.current?.token,
    });
    const data = await response.data.user;
    
    if (data && data.role === "admin")
      setUserData({ ...data });

    setUserLoaded(true);
  }, []);

  const resetUserData = useCallback(async () => {
    await axios.get(`${Config.apiUrl}/user/logout`, {
      withCredentials: true,
      cancelToken: axiosCancelSource.current?.token,
    });

    setUserData(null);
  }, []);

  useEffect(() => {
    axiosCancelSource.current = axios.CancelToken.source();
    getUserData();
    return () => axiosCancelSource.current?.cancel();
  }, [getUserData]);


  return (
    <UserContext.Provider
      value={{
        userData,
        userLoaded,
        setUserData,
        getUserData,
        resetUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = (): ContextType => {
  const context = useContext(UserContext);

  if (context == null) {
    throw new Error('useUserContext must be used within a UserProvider');
  }

  return context;
};