interface IProps {
  isNextPage: boolean;
  skip: number;
  nextPage: () => void;
  prevPage: () => void;
}

const Pagination = (props: IProps) => {
  const { isNextPage, skip, nextPage, prevPage } = props;

  return (
    <div className={`flex justify-end space-x-4 mt-4`}>
      {skip !== 0 && (
        <button type={'button'} className={'focus:outline-none bg-gray-700 hover:opacity-70 px-4 py-1 font-semibold uppercase text-sm rounded text-white text-center transition ease-in-out duration-300'} onClick={prevPage}>
          Back
        </button>
      )}
      {isNextPage && (
        <button type={'button'} className={'focus:outline-none bg-gray-700 hover:opacity-70 px-4 py-1 font-semibold uppercase text-sm rounded text-white text-center transition ease-in-out duration-300'} onClick={nextPage}>
          Next
        </button>
      )}
    </div>
  );
};

export default Pagination;