import { useEffect, useState } from "react";
import Config from "../../Config";

interface IProps {
  data: User;
  className: string;
}

const Avatar = (props: IProps) => {
  const { data, className } = props;
  
  const [ source, setSource ] = useState<string>('/default_avatar.png');

  useEffect(() => {
    setSource(`${Config.baseUrl}/${data.avatar?.url}`);
  }, [data]);

  const onError = () => {
    setSource('/default_avatar.png');
  }

  return (
    <img 
      className={`${className} bg-gray-200 border-2 border-gray-300 rounded-full object-cover`} 
      src={source} 
      alt={data.first_name} 
      onError={onError}
    />
  );
}

export default Avatar;