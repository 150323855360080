import axios from "../utils/axiosConfig";
import Config from "../Config";

const login = async (formData: { [key: string]: string }) => {
  const response = await axios.post(
    `${Config.apiUrl}/user/doLogin`, 
    new URLSearchParams(Object.entries(formData)).toString(),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  )
  const data = response.data;
  return data;
}

export default { login };