import moment from "moment";

interface IProps {
  data: User | null;
}

const DetailSummary = (props: IProps) => {

  const { data } = props;

  const items = [ 
    { label: "First Name", value: data?.first_name },
    { label: "Last Name", value: data?.last_name },
    { label: "User Name", value: data?.user_name },
    { label: "Email", value: data?.email },
    { label: "Role", value: data?.role },
    { label: "Subscription", value: data?.subscription?.planName || data?.subscription?.plan || 'N/A' },
    { label: "Subscription Id", value: data?.subscription?._id || '' },
    { label: "Curriculum", value: data?.curriculum_key },
    { label: "Last Active", value: data?.last_active ? moment(data?.last_active || "").fromNow() : 'N/A' },
    { label: "Created", value: String(data?.signup_date).split('T')[0] },
  ];

  return data !== null ? (
    <div>
      {items.map((item) => (
        <div key={item.label} className="flex flex-wrap justify-between px-2 py-1 border-b border-dashed border-gray-500 text-sm lg:text-base">
          <div className="w-full lg:w-auto">
            {item.label}
          </div> 
          <div className="font-semibold w-full lg:w-auto">
            {item.value}
          </div>
        </div>
      ))}
    </div>
  ) : <></>
}

export default DetailSummary;