import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Loading = () => {
  return (
    <div className="bg-white bg-opacity-60 fixed inset-0 z-50 flex h-screen justify-center items-center transition ease-in-out">
      <FontAwesomeIcon size="4x" icon={faCircleNotch} spin />
    </div>
  )
}

export default Loading;