import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { UserProvider } from "./contexts/User.context";
import Container from "./Container";

const App = () => {
  return (
    <div className="App">
      <UserProvider>
        <BrowserRouter>
          <ToastContainer />
          <Container />
        </BrowserRouter>
      </UserProvider>
    </div>
  )
}

export default App;
