import { Routes, Route } from "react-router-dom";
import { useUserContext } from "./contexts/User.context"
import Navbar from "./components/Navbar";
import EventPage from "./pages/Events";
import UserPage from "./pages/Users";
import DisputePage from "./pages/Disputes";
import UserViewPage from "./pages/User";
import SubscriptionPage from "./pages/Subscriptions";
import LoginPage from "./pages/Login";
import Loading from "./components/Loading";
import { useEffect, useState } from "react";

const Container = () => {

  const { userLoaded, userData } = useUserContext();
  const [ navbar, setNavbar ] = useState<boolean>(localStorage.getItem("navbar") === "1");

  useEffect(() => {
    if (typeof window === 'undefined') return;

    setNavbar(localStorage.getItem("navbar") === "1");
  }, []);

  const toggleNavbar = () => {
    setNavbar(!navbar);
    localStorage.setItem("navbar", navbar ? "0" : "1")
  }

  return (userLoaded && userData !== null) ? (
    <>
      <div className="flex">
        <div className={`${navbar ? 'w-72' : 'w-0'} fixed top-0 left-0 z-50 h-screen bg-gray-900`}>
          <Navbar 
            state={navbar}
            toggle={toggleNavbar}
          />
        </div>
        <div className={`w-full ${navbar ? 'pl-0 xl:pl-72' : 'pl-0'}`}>
          <Routes>
            <Route path="/" element={<UserPage />} />
            <Route path="/users/:query" element={<UserPage />} />
            <Route path="/users" element={<UserPage />} />
            <Route path="/u/:_id" element={<UserViewPage />} />
            <Route path="/subscriptions" element={<SubscriptionPage />} />
            <Route path="/disputes" element={<DisputePage />} />
            <Route path="/disputes/:type/:value" element={<DisputePage />} />
            <Route path="/events" element={<EventPage />} />
          </Routes>
        </div>
      </div>
    </>
  ) : (userLoaded && !userData) ? <LoginPage /> : <Loading />
}

export default Container;